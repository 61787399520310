<template>
  <div>
    <v-container>
      <v-row class="white">
        <v-col cols="2">
          <v-btn icon to="/" class="justify-center" color="blue-grey lighten-3">
            <v-icon>fas fa-angle-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" sm="8" class="d-flex justify-center">
          <h3 class="light-blue--text">CONTACT US</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-img
            alt="100 miles realtors services"
            class="shrink mt-2"
            src="../assets/contactus.jpg"
            transition="scale-transition"
            max-height="300"
            max-width="400"
          />
        </v-col>
        <v-col cols="12" class="">
          <span class="title px-3">
            <b>Address</b>
          </span>
          <div class="subtitle-1 text-justify px-3">
            Abhishek Colony, kathora Naka,<br />
            Tower line road Amravati (MH) 444604
            <a href="tel:+91-9923478787" class="routerlink"
              ><h4>9923478787</h4></a
            >
          </div>
        </v-col>
        <v-col class="py-4 text-center grey lighten-5 rounded-md" cols="12">
          <strong class="blue-grey--text text--darken-3 mb-4"
            >FOLLOW US ON</strong
          ><BR />
          <v-btn
            v-for="(icon, index) in icons"
            :key="index"
            class="mx-2 mt-4"
            :href="icon.to"
            :color="icon.color"
            icon
          >
            <v-icon size="35px">
              {{ icon.symbol }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="text-center my-3">
        <div>
          If you already bought property from us
          <v-btn text color="primary" to="/hmp/review">Post Your Review</v-btn>
        </div>
      </v-row>
      <v-row class="my-3">
        <v-col
          class="grey lighten-4 py-4 text-center blue-grey--text"
          cols="12"
        >
          <strong> 100 Miles Realtors © </strong> All Rights Reserved
          {{ new Date().getFullYear() }}<br />
          Design & Developed By :
          <span
            ><a
              href="https://goo.gl/maps/CUwJ361Dd2MSKmdJ6"
              target="_blank"
              class="routerlink blue-grey--text text--darken-3"
              ><strong>Prevelance Technology</strong></a
            ></span
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ContactUs",
  data() {
    return {
      // sImg: "../assets/contactus.jpg",
      icons: [
        {
          symbol: "fab fa-facebook",
          to: "https://www.facebook.com/100milesrealtor",
          color: "light-blue darken-3",
        },
        {
          symbol: "fab fa-instagram",
          to: "https://www.instagram.com/100_miles_realtor",
          color: "pink accent-3",
        },
        {
          symbol: "fab fa-whatsapp",
          to: "https://wa.me/919923478787",
          color: "green lighten-1",
        },
        {
          symbol: "fab fa-twitter",
          to: "https://www.twitter.com/100milesrealtor",
          color: "light-blue lighten-2",
        },
        {
          symbol: "fab fa-youtube",
          to: "https://www.youtube.com/channel/UCdVotlJm9I_3V5x0r8tSABw",
          color: "red",
        },
        {
          symbol: "fab fa-telegram-plane",
          to: "https://t.me/hundredmilesrealtor",
          color: "teal lighten-2",
        },
        {
          symbol: "as fa-mobile-alt",
          to: "tel:+91-9923478787",
          color: "amber darken-3",
        },
      ],
    };
  },
};
</script>
<style scoped>
.contact-title-bg {
  background-image: linear-gradient(235deg, #c2e4fe 0%, #0190ff 100%);
}
.prevalence-text-color {
  color: #212121;
}
</style>
